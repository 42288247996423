/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    code: "code",
    div: "div",
    blockquote: "blockquote",
    h2: "h2",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Во время разработки периодически возникают ситуации, когда вы запустили,\nнапример, NodeJs сервер и в какой-то момент произошла ошибка. Вы прерываете\nпроцесс через ", React.createElement(_components.code, null, "Ctrl+C"), ", запускаете сервер заново, но в ответ видите ошибку\n", React.createElement(_components.code, null, "Error: listen EADDRINUSE: address already in use :::3000"), ". Самое неприятное с\nэтой ошибкой то, что вы вроде бы остановили процесс, однако несмотря на это,\nпроцесс так и не освободил порт и все еще крутится где-то в памяти. В этом посте\nречь пойдет о том, как отловить такие процессы и все же убить их."), "\n", React.createElement(_components.p, null, "Чтобы убить процесс в linux, вам необходимо использовать команду ", React.createElement(_components.code, null, "kill"), " с\nсигналом ", React.createElement(_components.code, null, "9"), " (SIGKILL):"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">kill</span> -9 <span class=\"token operator\">&lt;</span>PID<span class=\"token operator\">></span></code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Чтобы получить process id (PID), нужно выполнить другую команду. При чем в\nконкретно нашей ситуации, нам нужно найти процесс по номеру порта, который он\nзанял. Разновидностей команд для решения такой задачи есть довольно много и\nдалеко не все из этих программ будут установлены в системе, с которой вам\nпридется работать. Потому далее предлагаю несколько альтернативных команд, чтобы\nбыло из чего выбирать. Начну с той, которую обычно использую сам."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Обратите внимание, если процесс был запущен на порту ", React.createElement(_components.code, null, ":80"), " или если он был\nзапущен другим юзером, скорее всего вам понадобится добавить ", React.createElement(_components.code, null, "sudo"), ", чтобы\nвыполнить команду с правами администратора, иначе со своего юзера вы либо не\nсможете увидеть процессы, либо не увидите их PID."), "\n"), "\n", React.createElement(_components.h2, {
    id: "lsof",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#lsof",
    "aria-label": "lsof permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "lsof"), "\n", React.createElement(_components.p, null, "Чтобы проверить какой процесс слушает на порту ", React.createElement(_components.code, null, "3000"), ", необходимо ввести\nследующую команду:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">lsof</span> -i :3000</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Расшифровку ключей можно посмотреть\n", React.createElement(_components.a, {
    href: "https://explainshell.com/explain?cmd=lsof+-t+-i%3A3000"
  }, "здесь")), "\n", React.createElement(_components.p, null, "В ответ получите нечто на подобии этого:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"text\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-text line-numbers\"><code class=\"language-text\">COMMAND    PID        USER   FD   TYPE  DEVICE SIZE/OFF NODE NAME\nnode    241907 sleepwalker   38u  IPv6 3609283      0t0  TCP *:3000 (LISTEN)</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Можно так же передать флаг ", React.createElement(_components.code, null, "-t"), ", чтобы получить только pid. Это дает возможность\nубить нужный процесс в одну строку:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">kill</span> -9 <span class=\"token variable\"><span class=\"token variable\">$(</span><span class=\"token function\">lsof</span> -t -i:3000<span class=\"token variable\">)</span></span></code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.h2, {
    id: "fuser",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#fuser",
    "aria-label": "fuser permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "fuser"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">fuser</span> -v -n tcp <span class=\"token number\">3000</span></code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "вывод команды будет выглядеть так:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"text\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-text line-numbers\"><code class=\"language-text\">                     USER        PID ACCESS COMMAND\n3000/tcp:            sleepwalker  367896 F.... node</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Расшифровку ключей можно посмотреть\n", React.createElement(_components.a, {
    href: "https://explainshell.com/explain?cmd=fuser+-v+-n+tcp+3000"
  }, "здесь")), "\n", React.createElement(_components.p, null, "Более короткий вариант команды:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">fuser</span> <span class=\"token number\">3000</span>/tcp</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Кроме того, можно сразу попросить ", React.createElement(_components.code, null, "fuser"), " убить процесс:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">fuser</span> -k <span class=\"token number\">3000</span>/tcp</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.h2, {
    id: "ss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ss",
    "aria-label": "ss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ss"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\">ss -tlpn</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "вывод команды будет выглядеть так:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"text\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-text line-numbers\"><code class=\"language-text\">State    Recv-Q   Send-Q      Local Address:Port        Peer Address:Port   Process\nLISTEN   0        511             127.0.0.1:3000             0.0.0.0:*       users:((\"node\",pid=365730,fd=22))\nLISTEN   0        511             127.0.0.1:42679            0.0.0.0:*       users:((\"code\",pid=338363,fd=109))\nLISTEN   0        5               127.0.0.1:631              0.0.0.0:*\nLISTEN   0        1                 0.0.0.0:7070             0.0.0.0:*</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Расшифровку ключей можно посмотреть\n", React.createElement(_components.a, {
    href: "https://explainshell.com/explain?cmd=ss+-tlpn"
  }, "здесь")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Самый простой способ сократить вывод команды — использовать ", React.createElement(_components.code, null, "grep"), ":\n", React.createElement(_components.code, null, "ss -tlpn | grep 3000"), "."), "\n"), "\n", React.createElement(_components.h2, {
    id: "netstat",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#netstat",
    "aria-label": "netstat permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "netstat"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">netstat</span> -tlpn</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Вот так будет выглядеть вывод команды:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"text\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-text line-numbers\"><code class=\"language-text\">Active Internet connections (only servers)\nProto Recv-Q Send-Q Local Address           Foreign Address         State       PID/Program name\ntcp        0      0 :::3000                 :::*                    LISTEN      25/.pm2)\ntcp        0      0 :::27017                :::*                    LISTEN      -</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Расшифровку ключей можно посмотреть\n", React.createElement(_components.a, {
    href: "https://explainshell.com/explain?cmd=netstat+-tlpn"
  }, "здесь"), ". Флаг ", React.createElement(_components.code, null, "-t"), "\nотфильтровывает только TCP соединения."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Самый простой способ сократить вывод команды — использовать ", React.createElement(_components.code, null, "grep"), ":\n", React.createElement(_components.code, null, "netstat -tlpn | grep 3000"), "."), "\n"), "\n", React.createElement(_components.h2, {
    id: "выводы",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D0%B2%D1%8B%D0%B2%D0%BE%D0%B4%D1%8B",
    "aria-label": "выводы permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Выводы"), "\n", React.createElement(_components.p, null, "В этом посте было рассмотрено 4 утилиты, которые помогут найти и убить процесс,\nкоторый занял порт. Надеюсь, эта информация окажется вам полезной и спасет вас\nот лишних затрат времени на поиски решения в будущем."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
